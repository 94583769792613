<template>
  <div id="app">
    <nav>
      <router-link to="/"></router-link>
      <!-- <router-link to="/about">About</router-link> -->
    </nav>
    <router-view />
  </div>
</template>

<script>
// import devPixelRatio from "@/utils/devicePixelRatio.js"

export default {
  created () {
    // new devPixelRatio().init() // 初始化页面比例
  },
};
</script>
<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
#app {
  box-sizing: border-box;
  width: 1920px;
  margin: 0 auto;
}
// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
