//导入request.js
import instance from "@/utils/request"
import { ajax } from "./ajax";
// 查询定价套餐信息
export function ysySystemMealHome (parameter) {
  return instance({
    url: '/ysy/systemMeal/home',
    method: 'get',
    params: parameter
  })
}
// 帮助中心获取模块列表
export function ysyVideoTutorialModule (parameter) {
  return instance({
    url: '/ysy/video/tutorial/module',
    method: 'post',
    data: parameter
  })
}
// 帮助中心获取指定模块下的视频列表
export function ysyVideoTutorialList (parameter) {
  return instance({
    url: '/ysy/video/tutorial/list',
    method: 'post',
    data: parameter
  })
}
// 下载软件最新版本
export function ysyVersionCurrent (parameter) {
  return instance({
    url: '/ysy/version/current',
    method: 'post',
    data: parameter
  })
}

export const ysyUserHelpCenterSearch = (params) => ajax("/ysy/user/help/center/search", params, "POST");

export const ysyHelpCenterList = (params) => ajax("/ysy/user/help/center/list", params, "POST")
