/**
 * import { useStore } from "vuex";
 * const store = useStore();
 * state: store.state.demo.tree
 * mutations: store.commit("demo/SET_PORWE", []])
 * actions : store.dispatch("demo/resetTree");
 * getters: store.state.demo.getFiveLenth)
 */
export default {
    namespaced: true,
    state: {
        tree: [1, 2, 3, 4, 5], // 保存菜单信息
    },
    mutations: {
        // 设置菜单
        SET_PORWE: (state, val) => {
            state.tree = val;
        },
    },
    actions: {
        resetTree: function (context) {
            setTimeout(() => {
                context.commit("SET_PORWE", []);

                // context.commit("xxx/sssss")  other namespaced mutations
            }, 2000);
        },
    },
    getters: {
        getFiveLenth(state, val) {
            return state.tree.filter((i) => i == 5).length;
        },
    },
};
