import Vue from 'vue'
import Vuex from 'vuex'
import { ysySystemMealHome } from '@/api/index'

let menuName = []
// ysySystemMealHome().then(response => {
//   for (const i in response.data.data) {
//     menuName.push(response.data.data[i].menuName)
//   }
// }).catch(error => {
//   console.log(error)
// })

Vue.use(Vuex)


// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context("./modules", true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    // set './app.js' => 'app'
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = modulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});



export default new Vuex.Store({
  state: {
    menuName
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ...modules
  }
})
