import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/font.css'

Vue.use(ElementUI)
import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  /* 李江 - 202404071120 - 修改：由于域名备案的原因，将网站的title固定为：郑州帮你印软件科技 */
  /*let title = '影素云-'
  if (to.meta.title) {
    document.title = title + to.meta.title
  }*/
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
