import { ysyUserHelpCenterSearch } from "@/api";
import md5 from "md5";

/**
 * import { useStore } from "vuex";
 * const store = useStore();
 * state: store.state.demo.tree
 * mutations: store.commit("demo/SET_PORWE", []])
 * actions : store.dispatch("demo/resetTree");
 * getters: store.state.demo.getFiveLenth)
 */

let messageHistory = localStorage.getItem("customerServiceMessageHistory")
try {
    messageHistory = JSON.parse(localStorage.getItem("customerServiceMessageHistory")) || {}
    Object.keys(messageHistory).map(i => {
        // 保留7天的记录， 以最后一条消息为准
        if (Date.now() - messageHistory[i][messageHistory[i].length - 1].addTime > 24 * 60 * 60 * 7){
            delete messageHistory[i]
        }
    })
    localStorage.setItem("customerServiceMessageHistory", JSON.stringify(messageHistory));
} catch(err){
    messageHistory = {}

}

// 获取对象中最大的KEY
let lastKey = Object.keys(messageHistory).length > 0 ? Math.max(...(Object.keys(messageHistory).map((i) => Number(i)))) : null
// 如果消息是24小时以前的，开启新的消息
let messageList = []
if (lastKey){

    // 取指定消息的最后一条消息作为对比
    // 如果消息是一天前的 开启新的聊天
    if (Date.now() - messageHistory[lastKey][messageHistory[lastKey].length - 1].addTime > 24 * 60 * 60 * 1000){
        lastKey = Date.now()
    } else {
        messageList = messageHistory[lastKey] || [];
    }
} else {
    lastKey = Date.now()
}



console.log(lastKey);
export default {
    namespaced: true,
    state: {
        msgId: lastKey,
        replayTimeOut: 600, // 无网络调用时的回复延迟
        messageList: [
            // {
            //   type: "text", // 消息类型，确定使用的布局展示方式 视频消息 图文消息 普通文本消息 纯图片消息 选择回答消息
            //   sender: 1, // 发送者  1自己  2客服
            //   message: "123123123123123123"
            // },
            // {
            //   type: "video", // 消息类型，确定使用的布局展示方式 视频消息 图文消息 普通文本消息 纯图片消息 选择回答消息
            //   sender: 1, // 发送者  1自己  2客服
            //   message: "123123123123123123"
            // },
            // {
            //   type: "select", // 消息类型，确定使用的布局展示方式 视频消息 图文消息 普通文本消息 纯图片消息 选择回答消息
            //   sender: 2, // 发送者  1自己  2客服
            //   message: "123123123123123123",
            //   selectList: [
            //     {
            //       title: '如何创建自定义产品尺寸'
            //     },
            //     {
            //       title: '如何设计店内作品'
            //     },
            //     {
            //       title: '如何进行毕业季整板批量设计'
            //     },
            //     {
            //       title: '如何导入本地模板'
            //     },
            //   ],
            // },
            // {
            //   type: "text", // 消息类型，确定使用的布局展示方式 视频消息 图文消息 普通文本消息 纯图片消息 选择回答消息
            //   sender: 2, // 发送者  1自己  2客服
            //   message: "123123123123123123"
            // },


            ...messageList
        ], // 保存菜单信息
        chatInput: "",
    },
    mutations: {
        // 设置菜单
        ADD_MESSAGE: (state, val) => {
            val.addTime = new Date().getTime();
            state.messageList.push(val);
            // 存储最近的100条数据
            messageHistory[state.msgId] = state.messageList
            localStorage.setItem("customerServiceMessageHistory", JSON.stringify(messageHistory));
        },
        SET_MESSAGE_INPUT: (state, val) => {
            state.chatInput = val || "";
        },
    },
    actions: {
        addMessage(context, val){
            if (val.sender == 2){
                // 回复延迟600ms
                setTimeout(() => {
                    context.commit("ADD_MESSAGE", val);
                }, context.state.replayTimeOut)
            } else {
                context.commit("ADD_MESSAGE", val);
            }

        },
        sendMessage(context, msg) {
            context.dispatch("addMessage", {
                type: "text",
                sender: 1,
                message: msg,
            });

            // 获取回复
            context.dispatch("getReplay", msg);
        },
        async getReplay(context, val) {
            let ret = await geQuestion(val);
            if (ret.length == 0) {
                // 未找到匹配的问题
                // 获取推荐的问题
                let recommend = await geQuestion();
                context.dispatch("addMessageByQuestionList", {
                    list: recommend,
                    type: "noAnswer",
                });
            } 
            // else if (ret.length == 1) {
            //     // 精准匹配到问题
            //     let answer = ret[0];
            //     context.dispatch("addMessageByQuestion", answer);
            // }
             else {
                // 模糊匹配到多个问题
                context.dispatch("addMessageByQuestionList", {
                    list: ret,
                    type: "recommend",
                });
            }
        },
        /**
         * 根据问题回复消息
         * @param {*} state
         * @param {*} val
         */
        addMessageByQuestion: function (context, question) {
            if (question.videoUrl) {
                context.dispatch("addMessage", {
                    type: "video",
                    sender: 2,
                    videoUrl: question.videoUrl,
                    videoCover: question.videoCover,
                    answer: question,
                });
            }
            if (question.content) {
                context.dispatch("addMessage", {
                    type: "imageContent",
                    sender: 2,
                    content: question.content,
                });
            }
        },
        /**
         * 添加问题列表消息
         * @param {*} state
         * @param {*} val
         * @param {*} tipsType
         */
        addMessageByQuestionList: function (context, val, tipsType) {
            const list = val.list;
            const type = val.type;
            context.dispatch("addMessage", {
                type: "select",
                sender: 2,
                selectList: list.splice(0, 6),
                tipsType: type,
            });
        },
    },
    getters: {
        getFiveLenth(state, val) {
            return state.tree.filter((i) => i == 5).length;
        },
    },
};

async function geQuestion(text) {
    let ret = await ysyUserHelpCenterSearch({
        keys: text ? text.trim() : "",
    });
    if (ret.code == 0) {
        return ret.data;
    }
}
