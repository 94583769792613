//引入axios
import axios from "axios"
// import { baseUrl } from './url'
//创建axios实例
const instance = axios.create({
  // baseUrl: baseUrl,
  // baseURL: 'https://testapi.yingsuyun.com',//测试地址
  baseURL: 'https://api.yingsuyun.com',//正式地址
  timeout: 10000,//请求超时时间
})
// 添加请求拦截器
instance.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(response => {
  // 对响应数据做点什么
  return response

}, error => {
  // 对响应错误做点什么
  return Promise.reject(error)
})

//导出axios实例
export default instance
