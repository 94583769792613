import axios from "axios";
// import { baseUrl } from "./url";
import router from "../router";
import md5 from "md5";
//导入request.js
import instance from "@/utils/request"
var request = instance
function sortObj(params) {
    let sortedStr = "";
    let keys = Object.keys(params).sort();
    for (let key of keys) {
        if (Array.isArray(params[key])) {
            if (Object.prototype.toString.call(params[key][0]) === "[object Object]") {
                for (let obj of params[key]) {
                    sortedStr += key + "=" + sortObj(obj);
                }
            } else {
                sortedStr += key + "=" + JSON.stringify(params[key]);
            }
        } else if (Object.prototype.toString.call(params[key]) === "[object Object]") {
            sortedStr += key + "=" + sortObj(params[key]);
        } else {
            sortedStr += key + "=" + params[key];
        }
    }
    return sortedStr;
}

export function ajax(url, params = {}, method = "GET", opt = {}) {
    let paramData = JSON.parse(JSON.stringify(params));
    delete params.sourceFrom;
    let weiailiushengToken = localStorage.getItem("ysyLoveShopInfo") ? JSON.parse(localStorage.getItem("ysyLoveShopInfo")).token : "";
    var token = paramData.sourceFrom == "yingsuyun" ? weiailiushengToken : localStorage.token || "test";
    var timestamp = new Date().getTime();
    if (opt.isForm) {
        config.headers["Content-Type"] = "multipart/form-data";
    }

    //axios会自动过滤值为undefined和null的参数，因此手动去掉这些参数，不让其参与签名，避免出现签名错误

    if (url.indexOf("?") > -1) {
        let urlParams = getUrlParams(url);
        if (Object.keys(urlParams).length) {
            Object.assign(params, urlParams);
        }
    }

    for (let key in params) {
        if (params[key] == null) {
            delete params[key];
        }
        // 获取产品不需要处理，产品名称查询加有[]的会被整体编码
        // let reg = /[\[\]]/g;
        // if (method === "GET" && typeof params[key] == "string" && reg.test(params[key])) {
        //     params[key] = encodeURI(params[key]);
        // }
    }

    if (Object.keys(params).length == 0) {
        params = {
            1: 1,
        };
    }

    const sign = md5(sortObj(params) + (paramData.sourceFrom == "yingsuyun" ? "02093cbdb78d447db7b15b2fc3ac218b" : "4Rc8393b71704FAAr36279d4a1EB7f5f34"));
    var config = {
        url: url,
        method: method,
        headers: {
            token: token,
            timestamp: timestamp,
            sign: sign,
        },
    };
    method.toLocaleUpperCase() === "GET" && (config.params = params);
    method.toLocaleUpperCase() === "POST" && (config.data = params);
    return new Promise((resolve, reject) => {
        request(config)
            .then((res) => {
                if (res.data.code == -2) {
                    localStorage.token = "";
                    router.replace("/login");
                } else {
                    resolve(res.data);
                }
            })
            .catch((res) => {
                reject(res);
            });
    });
}

function getUrlParams(url) {
    let queryIndex = url.indexOf("?");
    if (queryIndex > -1) {
        let query = url.substr(queryIndex + 1);
        if (query) {
            var strs = query.split("&");
            var options = {};
            for (var i = 0; i < strs.length; i++) {
                options[strs[i].split("=")[0]] = strs[i].split("=")[1];
            }
            return options;
        } else {
            return "";
        }
    }
}
